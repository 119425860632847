<template>
  <v-row class="app-quotation-preview">
    <v-col
      cols="12"
      md="9"
    >
      <v-card class="clerical-quotation">
        <!-- Header -->
        <v-card-text class="pa-8">
          <div class="quotation-header d-flex flex-wrap justify-space-between">
            <!-- Left Content -->
            <div class="text-left">
              <div class="d-flex align-center mb-6">
                <v-img
                  :src="organization.logo_url || require('@/assets/images/logo.png')"
                  max-height="30px"
                  max-width="30px"
                  alt="logo"
                  contain
                  class="me-3"
                ></v-img>
                <span class="text--primary font-weight-bold text-xl">
                  {{ organization.display_name }}
                </span>
              </div>
              <span class="d-block">{{ organization.name }} ({{ organization.roc_number }})</span>
              <span class="d-block">{{ organization.address }}</span>
              <span class="d-block">Tel: {{ organization.contact }}</span>
              <span class="d-block">{{ organization.website }}</span>
            </div>
            <!-- Right Content -->
            <div class="text-right mt-5 mt-sm-0">
              <div class="mb-0 mt-sm-4 d-flex align-center">
                <span class="me-2">Date Issued: </span>
                <v-menu
                  v-model="isIssueDateMenuOpen"
                  :close-on-content-click="false"
                  :nudge-left="$vuetify.breakpoint.smAndDown ? 95 : 0"
                  transition="scale-transition"
                  offset-y
                  eager
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="quotationDateFormatted"
                      class="header-inputs flex-grow-0"
                      readonly
                      outlined
                      dense
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="quotation.quotation_date"
                    color="primary"
                    :first-day-of-week="1"
                    @input="isIssueDateMenuOpen = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="pa-8">
          <div class="d-flex justify-space-between flex-wrap flex-column flex-sm-row">
            <div>
              <div class="d-flex align-center mb-3">
                <p class="font-weight-semibold payment-details-header mb-0">
                  Quotation To:
                </p>
                <v-autocomplete
                  ref="customerList"
                  v-model="quotation.customer_id"
                  :items="customers"
                  cache-items
                  item-text="name"
                  item-value="id"
                  single-line
                  outlined
                  dense
                  hide-details
                  return-object
                  placeholder="Select Customer"
                  class="ml-3"
                  @change="quotation.customer = $event; quotation.customer_id = $event.id"
                >
                  <template #append-item>
                    <div class="pa-0 mt-2 text-center append-select">
                      <v-btn
                        block
                        depressed
                        color="primary"
                        class="rounded-0"
                        @click="isAddNewCustomerDialogOpen = true"
                      >
                        Add new customer
                      </v-btn>
                    </div>
                  </template>
                </v-autocomplete>
              </div>
              <template v-if="quotation.customer">
                <p class="mb-1">
                  {{ quotation.customer.name }}
                </p>
                <p class="mb-1">
                  {{ quotation.customer.company }}
                </p>
                <p
                  v-if="quotation.customer.address"
                  class="mb-1"
                >
                  {{ quotation.customer.address }}
                </p>
                <p class="mb-1">
                  {{ quotation.customer.contact_number }}
                </p>
                <p class="mb-0">
                  {{ quotation.customer.email }}
                </p>
              </template>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <!-- Add products -->
        <div class="add-products-form pa-8">
          <div class="add-products-header d-none d-md-flex">
            <div class="px-5 flex-grow-1 font-weight-semibold">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <span>Item</span>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <span>Unit Price</span>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <span>Quantity</span>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <span>Discount</span>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <span>Amount</span>
                </v-col>
              </v-row>
            </div>
            <div class="header-spacer"></div>
          </div>
          <span class="px-5 flex-grow-1 font-weight-semibold d-block d-sm-none">Item</span>
          <div
            v-for="(line_item, index) in quotation.line_items_attributes"
            :key="index"
            class="my-3"
          >
            <v-card
              outlined
              class="d-flex"
            >
              <!-- Left Form -->
              <div class="pa-5 flex-grow-1">
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-autocomplete
                      v-model="line_item.category_id"
                      :items="categories"
                      cache-items
                      item-text="name"
                      item-value="id"
                      single-line
                      outlined
                      dense
                      hide-details
                      placeholder="Select Category"
                      @change="fetchItems(line_item.category_id, index)"
                    >
                      <template #append-item>
                        <div class="pa-0 mt-2 text-center append-select">
                          <v-btn
                            block
                            depressed
                            color="primary"
                            class="rounded-0"
                            @click="isAddNewCategoryDialogOpen = true; selectedLineItemIndex = index; fetchCategoryGroups()"
                          >
                            Add new category
                          </v-btn>
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="line_item.category_id">
                  <v-col
                    cols="12"
                    md="4"
                    class="pt-0"
                  >
                    <v-autocomplete
                      v-if="itemOptionsLoaded"
                      v-model="line_item.item"
                      :items="itemOptions[index]"
                      item-text="name"
                      item-value="id"
                      single-line
                      outlined
                      dense
                      hide-details
                      return-object
                      placeholder="Select Item"
                      @change="line_item.price = line_item.item.unit_price; line_item.item_id = line_item.item.id; line_item.item_name = line_item.item.name"
                    >
                      <template #append-item>
                        <div class="pa-0 mt-2 text-center append-select">
                          <v-btn
                            block
                            depressed
                            color="primary"
                            class="rounded-0"
                            @click="isAddNewItemDialogOpen = true; selectedLineItemIndex = index; localItem.sales_category_id = line_item.category_id"
                          >
                            Add new item
                          </v-btn>
                        </div>
                      </template>
                    </v-autocomplete>
                    <v-text-field
                      v-else
                      :value="line_item.item_name"
                      :append-icon="icons.mdiMenuDown"
                      single-line
                      dense
                      outlined
                      hide-details
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    sm="4"
                    class="pt-0"
                  >
                    <span class="d-block d-sm-none">Unit Price</span>
                    <v-text-field
                      v-model.number="line_item.price"
                      outlined
                      dense
                      min="0"
                      type="number"
                      hide-details="auto"
                      placeholder="$"
                      @input="val => { line_item.price = Math.abs(parseFloat(val)) || 0 }"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    sm="4"
                    class="pt-0"
                  >
                    <span class="d-block d-sm-none">Quantity</span>
                    <v-text-field
                      v-model.number="line_item.quantity"
                      outlined
                      dense
                      min="0"
                      type="number"
                      hide-details="auto"
                      placeholder="Quantity"
                      @input="val => { line_item.quantity = Math.abs(val) || 0 }"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    sm="4"
                    class="pt-0"
                  >
                    <span class="d-block d-sm-none">Discount</span>
                    <v-text-field
                      v-model.number="line_item.discount"
                      outlined
                      dense
                      min="0"
                      type="number"
                      hide-details="auto"
                      placeholder="$"
                      @input="val => { line_item.discount = Math.abs(parseFloat(val)) || 0 }"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    sm="4"
                    class="pt-0"
                  >
                    <p class="my-2">
                      <span class="d-inline d-md-none">Amount: </span>
                      <span>${{ parseFloat((line_item.price * line_item.quantity) - line_item.discount).toFixed(2) }}</span>
                    </p>
                  </v-col>
                </v-row>
              </div>
              <!-- Item Actions -->
              <div class="d-flex flex-column item-actions rounded-0 pa-1">
                <v-btn
                  icon
                  small
                  @click="removeLineItem(index)"
                >
                  <v-icon size="20">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </div>
            </v-card>
          </div>
          <v-btn
            color="primary"
            class="mt-4"
            outlined
            @click="addLineItem"
          >
            Add Item
          </v-btn>
        </div>

        <!-- Save Customer Dialog -->
        <v-dialog
          v-model="isAddNewCustomerDialogOpen"
          width="500"
          persistent
        >
          <v-card>
            <v-card-title class="d-flex align-center mv-4 mb-4">
              New Customer
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                @click="isAddNewCustomerDialogOpen = false; saveCustomerForm.reset()"
              >
                <v-icon size="22">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-form
                ref="saveCustomerForm"
                v-model="saveCustomerValid"
              >
                <v-text-field
                  v-model="newCustomer.name"
                  outlined
                  dense
                  label="Name"
                  :rules="[validators.required]"
                  hide-details="auto"
                  class="mb-6"
                ></v-text-field>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="newCustomer.company"
                      outlined
                      dense
                      label="Company"
                      :rules="[validators.required]"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="newCustomer.gender"
                      :items="['Male', 'Female']"
                      outlined
                      dense
                      label="Gender"
                      :rules="[validators.required]"
                      hide-details="auto"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="newCustomer.email"
                      outlined
                      dense
                      label="Email"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="newCustomer.contact_number"
                      outlined
                      dense
                      label="Number"
                      :rules="[validators.required]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-textarea
                  v-model="newCustomer.note"
                  outlined
                  rows="3"
                  label="Notes"
                ></v-textarea>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                block
                :loading="saveCustomerLoading"
                :disabled="saveCustomerLoading || !saveCustomerValid"
                @click="saveCustomer"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Add Item Dialog -->
        <v-dialog
          v-model="isAddNewItemDialogOpen"
          width="500"
          persistent
        >
          <v-card>
            <v-card-title class="d-flex align-center mv-4 mb-4">
              <v-row>
                <span class="ml-3 mt-5">
                  New Item For This Category
                </span>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  small
                  class="mr-2 mt-4"
                  @click="isAddNewItemDialogOpen = false; saveItemForm.reset()"
                >
                  <v-icon size="22">
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-form
                ref="saveItemForm"
                v-model="saveItemValid"
              >
                <v-text-field
                  v-model="localItem.name"
                  outlined
                  dense
                  label="Name"
                  :rules="[validators.required]"
                  hide-details="auto"
                  class="mb-6"
                ></v-text-field>
                <v-textarea
                  v-model="localItem.description"
                  outlined
                  rows="3"
                  label="Description"
                  hide-details="auto"
                  class="mb-6"
                ></v-textarea>
                <v-text-field
                  v-model="localItem.unit_price"
                  outlined
                  dense
                  min="0"
                  type="number"
                  placeholder="Unit Price"
                  prefix="BND$"
                  hide-details="auto"
                  class="mb-6"
                ></v-text-field>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                block
                :loading="saveItemLoading"
                :disabled="saveItemLoading || !saveItemValid"
                @click="saveItem"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Add Category Dialog -->
        <v-dialog
          v-model="isAddNewCategoryDialogOpen"
          width="500"
          persistent
        >
          <v-card>
            <v-card-title class="d-flex align-center mv-4 mb-4">
              New Category
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                @click="isAddNewCategoryDialogOpen = false; saveCategoryForm.reset()"
              >
                <v-icon size="22">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-form
                ref="saveCategoryForm"
                v-model="saveCategoryValid"
              >
                <v-text-field
                  v-model="category.name"
                  outlined
                  dense
                  label="Name"
                  :rules="[validators.required]"
                  hide-details="auto"
                  class="mb-6"
                ></v-text-field>
                <v-autocomplete
                  v-model="category.category_group_id"
                  label="Category Group"
                  :items="categoryGroups"
                  item-text="name"
                  item-value="id"
                  single-line
                  outlined
                  dense
                  hide-details
                  return-object
                  class="mb-6"
                  @change="category.category_group_id = $event.id"
                ></v-autocomplete>
                <v-textarea
                  v-model="category.description"
                  outlined
                  rows="3"
                  label="Description"
                  hide-details="auto"
                  class="mb-6"
                ></v-textarea>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                block
                :loading="saveCategoryLoading"
                :disabled="saveCategoryLoading || !saveCategoryValid"
                @click="saveCategory"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-divider></v-divider>

        <!-- Total -->
        <v-card-text class="pa-8">
          <div class="d-flex justify-space-between flex-wrap flex-column flex-sm-row">
            <v-spacer></v-spacer>
            <div>
              <table class="w-full">
                <tr>
                  <td class="pe-16">
                    Subtotal:
                  </td>
                  <th class="text-right">
                    ${{ parseFloat(quotation.line_items_attributes.reduce((sum, item) => sum + (item.price * item.quantity), 0)).toFixed(2) }}
                  </th>
                </tr>
                <tr>
                  <td class="pe-16">
                    Total Discount:
                  </td>
                  <th class="text-right">
                    ${{ parseFloat(quotation.line_items_attributes.reduce((sum, item) => sum + item.discount, 0)).toFixed(2) }}
                  </th>
                </tr>
              </table>
              <v-divider class="my-3"></v-divider>
              <table class="w-full">
                <tr>
                  <td class="pe-16">
                    Total:
                  </td>
                  <th class="text-right">
                    ${{ parseFloat(quotation.line_items_attributes.reduce((sum, item) => sum + (item.price * item.quantity), 0) - quotation.line_items_attributes.reduce((sum, item) => sum + item.discount, 0)).toFixed(2) }}
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="px-8 py-6">
          <div class="mb-0">
            <p class="font-weight-semibold mb-3">
              Note:
            </p>
            <vue-editor
              v-model="quotation.note"
              placeholder="Will be printed on the quotation"
              :editor-toolbar="customToolBar"
              :class="{ 'dark-mode-toolbar': $vuetify.theme.dark }"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-card class="mb-6">
        <v-card-text>
          <v-btn
            color="primary"
            block
            :loading="saveFormLoading"
            :disabled="saveFormLoading"
            @click="saveQuotation"
          >
            Save
          </v-btn>
        </v-card-text>
      </v-card>
      <v-alert
        v-for="(error, index) in saveFormErrors"
        :key="index"
        border="left"
        color="error"
        dark
        text
      >
        {{ error }}
      </v-alert>

      <ActivityLog :activities="activities" />
    </v-col>
  </v-row>
</template>

<script>
import {
  ref,
  inject,
  onMounted,
  computed,
} from '@vue/composition-api'
import { VueEditor } from 'vue2-editor'
import { mdiClose, mdiMenuDown } from '@mdi/js'
import { useRouter } from '@core/utils'
import { required } from '@core/utils/validation'
import ActivityLog from '@/components/activity/ActivityLog'

export default {
  components: { VueEditor, ActivityLog },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const utilsService = inject('utilsService')
    const { route, router } = useRouter()
    const quotationId = route.value.params.id
    const organizationData = computed(() => store.state.organization)

    // Properties
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const quotation = ref({
      quotation_date: `${yearNow}-${monthNow}-${dayNow}`,
      line_items_attributes: [{
        item_id: null,
        category_id: null,
        name: null,
        price: 0.00,
        quantity: 1,
        discount: 0.00,
        amount: 0.00,
      }],
    })
    const organization = ref({ ...organizationData.value })
    const isIssueDateMenuOpen = ref(false)

    const customers = ref([])
    const newCustomer = ref({})
    const saveCustomerForm = ref(null)
    const saveCustomerValid = ref(false)
    const saveCustomerLoading = ref(false)
    const isAddNewCustomerDialogOpen = ref(false)

    const itemOptions = ref([])
    const localItem = ref({})
    const saveItemForm = ref(null)
    const saveItemValid = ref(false)
    const saveItemLoading = ref(false)
    const isAddNewItemDialogOpen = ref(false)
    const itemOptionsLoaded = ref(false)
    const selectedLineItemIndex = ref(null)
    const lineItemsToBeDeleted = ref([])

    const categories = ref([])
    const category = ref({})
    const categoryGroups = ref([])
    const saveCategoryForm = ref(null)
    const saveCategoryValid = ref(false)
    const saveCategoryLoading = ref(false)
    const isAddNewCategoryDialogOpen = ref(false)

    const activities = ref([])

    const saveFormLoading = ref(false)
    const saveFormErrors = ref([])
    const customToolBar = [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
    ]

    // Computed
    const quotationDateFormatted = computed(() => utilsService.formatDate(quotation.value.quotation_date))

    // Methods
    const fetchCustomers = () => {
      store
        .dispatch('clericalSettingsStore/fetchCustomers', { no_pagination: true })
        .then(response => {
          customers.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching customers. Please refresh!')
        })
    }
    const fetchItems = (categoryId = null, lineItemIndex = null) => {
      itemOptionsLoaded.value = false
      store
        .dispatch('clericalSettingsStore/fetchItems', { category_id: categoryId, no_pagination: true })
        .then(response => {
          quotation.value.line_items_attributes[lineItemIndex].itemOptions = response.data.data
          itemOptions.value.push(response.data.data)
          itemOptions.value[lineItemIndex] = response.data.data
          itemOptionsLoaded.value = true
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching items. Please refresh!')
        })
    }
    const fetchCategories = () => {
      store
        .dispatch('clericalSettingsStore/fetchCategories', { no_pagination: true })
        .then(response => {
          categories.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching categories. Please refresh!')
        })
    }
    const fetchCategoryGroups = () => {
      store
        .dispatch('knowledgeBaseStore/fetchCategoryGroups', { type: 'sales' })
        .then(response => {
          categoryGroups.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching category groups. Please refresh!')
        })
    }
    const fetchQuotation = id => {
      store
        .dispatch('quotationStore/fetchQuotation', { id, as_form: true })
        .then(response => {
          quotation.value = response.data.data
          const lineItems = quotation.value.line_items_attributes
          for (let i = 0; i < lineItems.length; i += 1) {
            fetchItems(lineItems[i].category_id, i)
          }
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching quotation. Please refresh!')
        })
    }
    const addLineItem = () => {
      quotation.value.line_items_attributes.push({
        item_id: null,
        category_id: null,
        price: 0.00,
        quantity: 1,
        discount: 0.00,
        amount: 0.00,
      })
    }
    const removeLineItem = index => {
      const lineItems = quotation.value.line_items_attributes
      if (lineItems[index].id) {
        lineItems[index]._destroy = true
        lineItemsToBeDeleted.value.push(lineItems[index])
      }
      lineItems.splice(index, 1)
    }
    const saveCustomer = () => {
      saveCustomerLoading.value = true
      store
        .dispatch('clericalSettingsStore/createCustomer', newCustomer.value)
        .then(response => {
          snackbarService.success(response.data.message)
          isAddNewCustomerDialogOpen.value = false
          saveCustomerLoading.value = false
          saveCustomerForm.value.reset()
          fetchCustomers()

          quotation.value.customer = response.data.data
          quotation.value.customer_id = response.data.data.id
        })
        .catch(error => {
          saveCustomerLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while adding customer. Please refresh!')
        })
    }
    const saveItem = () => {
      saveItemLoading.value = true
      store
        .dispatch('clericalSettingsStore/createItem', localItem.value)
        .then(response => {
          snackbarService.success(response.data.message)
          isAddNewItemDialogOpen.value = false
          saveItemLoading.value = false
          saveItemForm.value.reset()
          fetchItems(quotation.value.line_items_attributes[selectedLineItemIndex.value].category_id, selectedLineItemIndex.value)

          quotation.value.line_items_attributes[selectedLineItemIndex.value].item = response.data.data
          quotation.value.line_items_attributes[selectedLineItemIndex.value].item_id = response.data.data.id
          quotation.value.line_items_attributes[selectedLineItemIndex.value].price = response.data.data.unit_price
          selectedLineItemIndex.value = null
        })
        .catch(error => {
          saveItemLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while adding item. Please refresh!')
        })
    }
    const saveCategory = () => {
      saveCategoryLoading.value = true
      store
        .dispatch('clericalSettingsStore/createCategory', category.value)
        .then(response => {
          const { data, message } = response.data
          snackbarService.success(message)
          isAddNewCategoryDialogOpen.value = false
          saveCategoryLoading.value = false
          saveCategoryForm.value.reset()
          fetchCategories()

          fetchItems(data.id, selectedLineItemIndex.value)
          quotation.value.line_items_attributes[selectedLineItemIndex.value].category_id = data.id
          selectedLineItemIndex.value = null
        })
        .catch(error => {
          saveCategoryLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while adding category. Please refresh!')
        })
    }
    const saveQuotation = () => {
      saveFormLoading.value = true
      const quotationParams = { ...quotation.value }
      quotationParams.line_items_attributes = quotationParams.line_items_attributes.concat(lineItemsToBeDeleted.value)
      store
        .dispatch(`quotationStore/${quotationId ? 'updateQuotation' : 'createQuotation'}`, quotationParams)
        .then(response => {
          snackbarService.success(response.data.message)
          saveFormLoading.value = false
          router.push('/clerical/quotation')
        })
        .catch(error => {
          saveFormLoading.value = false
          saveFormErrors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while saving quotation. Please refresh!')
        })
    }
    const fetchActivities = () => {
      store
        .dispatch('quotationStore/fetchActivities', { id: quotationId })
        .then(response => {
          activities.value = response.data.data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching activities. Please refresh!')
        })
    }

    // Mounted
    onMounted(() => {
      fetchCustomers()
      fetchCategories()
      if (quotationId) {
        fetchQuotation(quotationId)
        fetchActivities()
      }
    })

    return {
      // Computed
      quotationDateFormatted,

      // Properties
      quotation,
      organization,
      isIssueDateMenuOpen,

      customers,
      newCustomer,
      saveCustomerForm,
      saveCustomerValid,
      saveCustomerLoading,
      isAddNewCustomerDialogOpen,

      itemOptions,
      localItem,
      saveItemForm,
      saveItemValid,
      saveItemLoading,
      isAddNewItemDialogOpen,
      itemOptionsLoaded,
      selectedLineItemIndex,

      categories,
      category,
      categoryGroups,
      saveCategoryForm,
      saveCategoryValid,
      saveCategoryLoading,
      isAddNewCategoryDialogOpen,
      activities,

      saveFormLoading,
      saveFormErrors,
      customToolBar,

      // Validators
      validators: {
        required,
      },

      // Icons
      icons: {
        mdiClose,
        mdiMenuDown,
      },

      // Methods
      fetchItems,
      fetchCategoryGroups,
      addLineItem,
      removeLineItem,
      saveCustomer,
      saveItem,
      saveCategory,
      saveQuotation,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/quotation.scss';

.clerical-quotation {
  .header-inputs {
    width: 122px;
  }

  .add-products-form {
    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}

.append-select {
  position: sticky;
  bottom: 1px;
  display: flex;
  justify-content: center;
}
</style>
